import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Button,
  Grow,
  CardMedia,
  ListItemText,
  List,
  ListItem,
} from "@mui/material";
import Equation from "../../components/Equation";
import Animation from "../../components/Animation";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ContentsList from "../../components/ContentsList";
import Teorie from "./Teorie";
import LabPompeCentrifugale from "./LabPompeCentrifugale";
import rotorsImage from "../../assets/centrifugal_pump/rotors.png";
import curbaPompeImage from "../../assets/centrifugal_pump/curba_pompe.png";
import Carousel from "react-material-ui-carousel";
import Slides from "./MediaSlides";
const PompeCentrifugale = () => {
  return (
    <div>
      <Container
        disableGutters
        maxWidth="md"
        component="main"
        sx={{ pt: 4, pb: 4 }}
      >
        <Typography
          component="h1"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Pompa Centrifugală
        </Typography>
      </Container>
      <Container>
        <Paper
          elevation={3}
          sx={{
            p: 5,
            // height: "60vh",
          }}
        >
          <Grid container justifyContent="space-evenly">
            <Grid
              item
              xs={8}
              sm={8}
              md={8}
              lg={6}
              xl={6}
              // display="flex"
            >
              <iframe
                title="Single Stage Centrifugal Pump"
                frameborder="0"
                allowfullscreen
                mozallowfullscreen="true"
                webkitallowfullscreen="true"
                allow="autoplay; fullscreen; xr-spatial-tracking"
                xr-spatial-tracking
                execution-while-out-of-viewport
                execution-while-not-rendered
                web-share
                src="https://sketchfab.com/models/d5c6a645a8634c75b8fe80f273ae4e1d/embed?autostart=1&annotations_visible=1&dnt=1"
                height="100%"
                width="100%"
              ></iframe>
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={6} xl={6} display="flex">
              <Paper
                sx={{
                  flexGrow: 1,
                  pb: 3,
                  pt: 5,
                }}
              >
                <Slides />
              </Paper>
            </Grid>
          </Grid>
          <Teorie />
          <LabPompeCentrifugale />
        </Paper>
      </Container>
    </div>
  );
};

export default PompeCentrifugale;
