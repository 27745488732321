import logo from "./logo.svg";
import "./App.css";
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { AppBar, Paper } from "@mui/material";
import { padding } from "@mui/system";
import WelcomePage from "./components/WelcomePage";
import TitleBar from "./components/TitleBar";
// import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import MenuDrawer from "./components/MenuDrawer";
import { DrawerProvider } from "./DrawerContext";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PompeCentrifugale from "./pages/PompeCentrifugale/PompeCentrifugale";
import PompeCuRotiDintate from "./components/PompeCuRotiDintate";
import SurubulLuiArhimede from "./components/SurubulLuiArhimede";
import PompaCuDiafragma from "./components/PompaCuDiafragma";
import PompaPeristaltica from "./components/PompaPeristaltica";
import PompaCuPistonaseAxiale from "./components/PompaCuPistonaseAxiale";
import PompaCuAngrenajeInterioare from "./components/PompaCuAngrenajeInterioare";
import LaboratorVirtual from "./components/LaboratorVirtual";

import CornerRibbon from "react-corner-ribbon";

import surub_arhimede from "./assets/surub_arhimede/surub_arhimede_sprite.png";
import gear_pump from "./assets/gear_pump/gear_pump_sprite.png";
import peristaltic_pump from "./assets/peristaltic_pump/peristaltic_pump_sprite.png";
import pompa_cu_angrenaje_interioare from "./assets/pompa_cu_angrenaje_interioare/pompa_cu_angrenaje_interioare_sprite.png";
import pompa_cu_pistonase_axiale from "./assets/pompa_cu_pistonase_axiale/pompa_cu_pistonase_axiale_sprite.png";

function App() {
  const [userIsOnMobile, setUserIsOnMobile] = useState(false);
  // variabila loading este folosita pentru a afisa un loading screen pana cand se incarca toate resursele
  const [loading, setIsLoading] = useState(true);
  console.log(loading);

  const cacheImages = async (srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image();

        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
    setIsLoading(false);
    console.log("finshed loading");
  };
  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // true for mobile device
      console.log("mobile device");
      setUserIsOnMobile(true);
    } else {
      // false for not mobile device
      console.log("not mobile device");
      setUserIsOnMobile(false);
    }

    const imgs = [
      surub_arhimede,
      gear_pump,
      pompa_cu_angrenaje_interioare,
      pompa_cu_pistonase_axiale,
      peristaltic_pump,
    ];
    cacheImages(imgs);
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <CornerRibbon
        position="top-right" // OPTIONAL, default as "top-right"
        fontColor="#f0f0f0" // OPTIONAL, default as "#f0f0f0"
        backgroundColor="#2c7" // OPTIONAL, default as "#2c7"
      >
        DEMO
      </CornerRibbon>
      {/* <Suspense> */}
      <Router>
        {!loading && (
          <div className="App">
            <DrawerProvider>
              <MenuDrawer />
              <TitleBar />
            </DrawerProvider>
            <Routes>
              <Route exact path="/" element={<WelcomePage />}></Route>
              <Route
                exact
                path="pompe-centrifugale"
                element={<PompeCentrifugale />}
              ></Route>
              <Route
                exact
                path="pompe-cu-roti-dintate"
                element={<PompeCuRotiDintate />}
              ></Route>
              <Route
                exact
                path="surubul-lui-arhimede"
                element={<SurubulLuiArhimede />}
              ></Route>
              <Route
                exact
                path="pompa-cu-diafragma"
                element={<PompaCuDiafragma />}
              ></Route>

              <Route
                exact
                path="pompa-peristaltica"
                element={<PompaPeristaltica />}
              ></Route>
              <Route
                exact
                path="pompa-cu-pistonase-axiale"
                element={<PompaCuPistonaseAxiale />}
              ></Route>
              <Route
                exact
                path="pompa-cu-angrenaje-interioare"
                element={<PompaCuAngrenajeInterioare />}
              ></Route>
              <Route
                exact
                path="laborator-virtual"
                element={<LaboratorVirtual />}
              ></Route>
            </Routes>
          </div>
        )}
      </Router>
      {/* </Suspense> */}
    </ThemeProvider>
  );
}

export default App;
