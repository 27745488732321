import React, { useState, useEffect } from "react";
import Equation from "./Equation";
import Animation from "./Animation";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ContentsList from "./ContentsList";
import Carousel from "react-material-ui-carousel";
import { Paper, Button, Grow, CardMedia } from "@mui/material";
import diaphragmPumpImage from "../assets/diaphragm_pump/1.gif";
import diaphragmPumpImage2 from "../assets/diaphragm_pump/2.gif";
function Slide1() {
  return (
    <div>
      <Typography
        sx={{}}
        component="h1"
        variant="h6"
        align="center"
        color="text.primary"
        gutterBottom
      >
        Aplicabilitate
      </Typography>
      <Box
        sx={{
          mt: 3,
          px: 10,
        }}
      >
        <Typography>
          Se folosește pentru fluide încărcate cu materii solide (noroi, ciment,
          apă uzată) sau foarte vâscoase (țiței, plastic sau cauciuc topit etc)
        </Typography>
      </Box>
    </div>
  );
}
function Slide2() {
  return (
    <Paper>
      <Button className="CheckButton"> slide 2 k it out!</Button>
    </Paper>
  );
}
const Slides = (props) => {
  return (
    <Carousel navButtonsAlwaysVisible={true} autoPlay={false} swipe={false}>
      <Slide1 />
      {/* <Slide2 /> */}
    </Carousel>
  );
};
const ImageSlides = (props) => {
  function Slide1() {
    return (
      <CardMedia
        component="img"
        width="auto"
        image={diaphragmPumpImage}
        alt={`Pompa cu Diafragmă`}
      />
    );
  }
  function Slide2() {
    return (
      <CardMedia
        component="img"
        width="auto"
        image={diaphragmPumpImage2}
        alt={`Pompa cu Diafragmă`}
      />
    );
  }
  return (
    <Carousel navButtonsAlwaysVisible={true} autoPlay={false} swipe={false}>
      <Slide1 />
      <Slide2 />
    </Carousel>
  );
};

const PompeCuDiafragma = () => {
  return (
    <div>
      <Container
        disableGutters
        maxWidth="md"
        component="main"
        sx={{ pt: 4, pb: 4 }}
      >
        <Typography
          component="h1"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Pompa cu Diafragmă
        </Typography>
      </Container>
      <Container>
        <Paper
          elevation={3}
          sx={{
            p: 5,
            // height: "60vh",
          }}
        >
          <Grid container justifyContent="space-evenly" spacing={4}>
            <Grid item xs={8} sm={8} md={8} lg={6} xl={6}>
              <ImageSlides />
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={6} xl={6} display="flex">
              <iframe
                title="Diaphragm pump (double headed construction)"
                height="100%"
                width="100%"
                frameborder="0"
                allowfullscreen
                mozallowfullscreen="true"
                webkitallowfullscreen="true"
                allow="autoplay; fullscreen; xr-spatial-tracking"
                xr-spatial-tracking
                execution-while-out-of-viewport
                execution-while-not-rendered
                web-share
                src="https://sketchfab.com/models/4c2fb4cd94ae494bb0e1630475a3db74/embed?autostart=1&dnt=1"
              ></iframe>
            </Grid>
            <Grid item xs={12} display="flex">
              <Paper
                sx={{
                  flexGrow: 1,
                  pb: 3,
                  pt: 5,
                }}
              >
                <Slides />
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default PompeCuDiafragma;
