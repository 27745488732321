import React, { useState, useEffect } from "react";
import Equation from "./Equation";
import Animation from "./Animation";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ContentsList from "./ContentsList";
import Carousel from "react-material-ui-carousel";
import { Paper, Button, Grow, Tab, Tabs } from "@mui/material";
function Slide1_crescent() {
  return (
    <div>
      <Typography
        sx={{}}
        component="h1"
        variant="h6"
        align="center"
        color="text.primary"
        gutterBottom
      >
        Utilizare
      </Typography>
      <Box
        sx={{
          mt: 3,
          px: 10,
        }}
      >
        <Typography>Folosita pentru fluide cu viscozitate mare.</Typography>
      </Box>
    </div>
  );
}
function Slide1_gerotor() {
  return (
    <div>
      <Typography
        sx={{}}
        component="h1"
        variant="h6"
        align="center"
        color="text.primary"
        gutterBottom
      >
        Utilizare
      </Typography>
      <Box
        sx={{
          mt: 3,
          px: 10,
        }}
      >
        <Typography>
          Utilizate în acționări hidrostatice, la debite foarte mici, sarcini
          medii (creșterea presiunii max 70 bar)
        </Typography>
      </Box>
    </div>
  );
}
const SlidesCrescent = (props) => {
  return (
    <Carousel
      indicators={true}
      navButtonsAlwaysVisible={true}
      autoPlay={false}
      swipe={false}
      sx={{
        height: "100%",
      }}
    >
      <Slide1_crescent />
      {/* <Slide2 /> */}
    </Carousel>
  );
};
const SlidesGerotor = (props) => {
  return (
    <Carousel
      indicators={true}
      navButtonsAlwaysVisible={true}
      autoPlay={false}
      swipe={false}
      sx={{
        height: "100%",
      }}
    >
      <Slide1_gerotor />
      {/* <Slide2 /> */}
    </Carousel>
  );
};
const Gerotor = () => {
  return (
    <Container>
      <Typography
        variant="h4"
        align="center"
        color="text.secondary"
        component="p"
      >
        <i>Gerotor Pump</i>
      </Typography>
      <Paper
        elevation={3}
        sx={{
          p: 5,
          mt: 5,
          // height: "60vh",
        }}
      >
        <Grid container spacing={8} justifyContent="space-evenly">
          <Grid item xs={8} sm={8} md={8} lg={6} xl={6}>
            {/* <Animation animation="pompa_cu_angrenaje_interioare" /> */}
            <iframe
              title="Gerotor Pump Mechanism"
              height="100%"
              width="100%"
              frameBorder="0"
              allowFullscreen
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
              allow="autoplay; fullscreen; xr-spatial-tracking"
              xr-spatial-tracking
              execution-while-out-of-viewport
              execution-while-not-rendered
              web-share
              src="https://sketchfab.com/models/6e50c187e4ff416fa97d73a7bb3267de/embed?autostart=1&ui_hint=0&dnt=1"
            ></iframe>{" "}
          </Grid>
          <Grid
            item
            xs={8}
            sm={8}
            md={8}
            lg={6}
            xl={6}
            display="flex"
            // alignItems="center"
            // justifyContent="center"
          >
            <Paper
              sx={{
                flexGrow: 1,
                pb: 3,
                pt: 5,
              }}
            >
              <SlidesGerotor />
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};
const Crescent = () => {
  return (
    <Container>
      <Typography
        variant="h4"
        align="center"
        color="text.secondary"
        component="p"
      >
        <i>Crescent Pump</i>
      </Typography>
      <Paper
        elevation={3}
        sx={{
          p: 5,
          mt: 5,
          // height: "60vh",
        }}
      >
        <Grid container spacing={8} justifyContent="space-evenly">
          <Grid item xs={8} sm={8} md={8} lg={6} xl={6}>
            <Animation animation="pompa_cu_angrenaje_interioare" />
          </Grid>
          <Grid
            item
            xs={8}
            sm={8}
            md={8}
            lg={6}
            xl={6}
            display="flex"
            // alignItems="center"
            // justifyContent="center"
          >
            <iframe
              width="100%"
              height="100%"
              title="Internal gear pump"
              frameBorder="0"
              allowFullScreen
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
              allow="autoplay; fullscreen; xr-spatial-tracking"
              xr-spatial-tracking
              execution-while-out-of-viewport
              execution-while-not-rendered
              web-share
              src="https://sketchfab.com/models/67371ffe8c9547059c035dcef2c23e85/embed?autostart=1&annotations_visible=1&ui_hint=0&dnt=1"
            ></iframe>
          </Grid>
          <Grid item xs={12}>
            <Paper
              sx={{
                flexGrow: 1,
                pb: 3,
                pt: 5,
              }}
            >
              <SlidesCrescent />
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

const PompaCuAngrenajeInterioare = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue);
  };
  return (
    <div>
      <Container
        disableGutters
        maxWidth="md"
        component="main"
        sx={{ pt: 4, pb: 4 }}
      >
        <Typography
          component="h1"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Pompa cu Angrenaje Interioare
        </Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          textColor="primary"
          indicatorColor="primary"
          aria-label="secondary tabs example"
        >
          <Tab
            sx={{
              fontSize: 20,
            }}
            label="Crescent"
          />
          <Tab
            sx={{
              fontSize: 20,
            }}
            label="Gerotor"
          />
        </Tabs>
      </Container>
      {value ? <Gerotor /> : <Crescent />}
      {/* <Gerotor /> */}
    </div>
  );
};

export default PompaCuAngrenajeInterioare;
