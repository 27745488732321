import React from "react";

import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import ContentsList from "./ContentsList";
import { Grid3x3 } from "@mui/icons-material";
import theme from "../theme";
import Animation from "./Animation";

const WelcomePage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container
        disableGutters
        maxWidth="md"
        component="main"
        sx={{ pt: 8, pb: 4 }}
      >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Heracles
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          component="p"
        >
          Platformă de <i>e-learning</i>
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="text.secondary"
          component="p"
        >
          Află totul despre lumea acționărilor hidraulice
        </Typography>
      </Container>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
          }}
        >
          <Grid container justifyContent="space-evenly">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              sx={
                {
                  // backgroundColor: "red",
                }
              }
              display="flex"
              justifyContent="center" //in MUI v5 this prop is renamed justifyContent
              flexDirection="column"
              alignItems="center"
            >
              <Typography
                variant="h5"
                align="left"
                color="text.primary"
                component="p"
                sx={{
                  mb: 4,
                }}
              >
                Cuprins
              </Typography>
              <ContentsList />
            </Grid>
            <Grid
              sx={
                {
                  // backgroundColor: "blue",
                }
              }
              item
              xs={8}
              sm={8}
              md={8}
              lg={6}
              xl={6}
            >
              <Animation animation="gear_pump" delay="5000" />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default WelcomePage;
