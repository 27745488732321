import React from "react";
import MathJax from "react-mathjax2";
import { Typography } from "@mui/material";
var Latex = require("react-latex");
var eq = "$ \\int_{1}^{2} \\infty$";
const Equation = () => {
  return (
    <div>
      <Latex displayMode={true}>{eq}</Latex>
      <Typography>sal</Typography>
    </div>
  );
};

export default Equation;
