import React from "react";
import { Box } from "@mui/material";
const LaboratorVirtual = () => {
  return (
    <Box
      sx={{
        height: "90vh",
      }}
    >
      <iframe
        src="https://vladstroia.github.io/heracles_jupyter/lab/index.html"
        // src="https://hub.gke2.mybinder.org/user/nghweigeok-jupyter_webapp_demo-vkondmzc/voila/render/Demo.ipynb?token=W8wlNannTSSxT2WXa8hZtA"
        width="100%"
        height="100%"
      ></iframe>
    </Box>
  );
};

export default LaboratorVirtual;
