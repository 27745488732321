import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function TabelDate(props) {
  // console.log(props.graphData);

  return (
    <TableContainer
      component={Paper}
      sx={{
        mt: 3,
      }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Debit [L / min]</TableCell>
            <TableCell align="right">Sarcina la intrare [m]</TableCell>
            <TableCell align="right">Sarcina la iesire [m]</TableCell>
            <TableCell align="right">Inaltime de pompare [m]</TableCell>
            <TableCell align="right"> Puterea consumata [W]</TableCell>
            <TableCell align="right">Puterea utila [W]</TableCell>
            <TableCell align="right">Randamentul [%]</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.graphData.randament.map((object, i) => (
            <TableRow
              key={i}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {props.graphData.randament[i].x}
              </TableCell>
              <TableCell align="right">
                {props.graphData.sarcinaLaIntrare[i].y}
              </TableCell>
              <TableCell align="right">
                {props.graphData.sarcinaLaIesire[i].y}
              </TableCell>
              <TableCell align="right">
                {props.graphData.inaltimeDePompare[i].y}
              </TableCell>
              <TableCell align="right">
                {props.graphData.putereConsumata[i].y}
              </TableCell>
              <TableCell align="right">
                {props.graphData.putereUtila[i].y}
              </TableCell>
              <TableCell align="right">
                {props.graphData.randament[i].y}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
