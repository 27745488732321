import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Scatter } from "react-chartjs-2";

import { Grid, Box } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Grafic",
    },
  },
};

// const labels = ["January", "February", "March", "April", "May", "June", "July"];

export function Grafic(props) {
  // const [graphData, setGraphData] = useState(props.graphData);
  // console.log(props.graphData);

  const data1 = {
    datasets: [
      {
        label: "Randament",
        data: props.graphData.randament,
        borderColor: "rgb(100, 99, 132)",
        backgroundColor: "rgba(100, 99, 132, 0.5)",
      },
    ],
  };
  const data2 = {
    datasets: [
      {
        label: "Înălțimea de Pompare [m]",
        data: props.graphData.inaltimeDePompare,
        borderColor: "#3e95cd",
        backgroundColor: "rgba(121, 164, 46, 1)",
      },
    ],
  };
  const data3 = {
    datasets: [
      {
        label: "Puterea Consumată [W]",
        data: props.graphData.putereConsumata,
        borderColor: "rgb(255, 99, 0)",
        backgroundColor: "gba(255, 99, 0, 0.5)",
      },
    ],
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid sx={{ mt: 3, flexGrow: 1 }} container spacing={2}>
        <Grid item md={4}>
          <Scatter options={options} data={data1} />
        </Grid>
        <Grid item md={4}>
          <Scatter options={options} data={data2} />
        </Grid>
        <Grid item md={4}>
          <Scatter options={options} data={data3} />
        </Grid>
      </Grid>
    </Box>
  );
}
