import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import { Box, Autocomplete, TextField, Button } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import WelcomePage from "./WelcomePage";
import DrawerContext from "../DrawerContext";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { chapters } from "./ContentsList"; //  NAV BAR

import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

function TitleBar() {
  const location = useLocation();
  console.log(location.pathname);
  const { drawerState, toggleDrawer } = useContext(DrawerContext);
  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{
              mr: 2,
            }}
            onClick={toggleDrawer("left", true)}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/">
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                color: "#ffffff",
                display: {
                  xs: "none",
                  sm: "block",
                },
              }}
            >
              Heracles
            </Typography>
          </Link>
          <Box
            sx={{
              flexGrow: 1,
            }}
          ></Box>
          {/* {location.pathname != "/laborator-virtual" && <Link to="/laborator-virtual" target="_blank" rel="noopener noreferrer">
              <Button sx={{
            color: "#ffffff",
            textTransform: "none"
          }} // variant="contained"
          endIcon={<SubdirectoryArrowRightIcon />}>
                Laborator Virtual
              </Button>
            </Link>} */}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export { TitleBar as default };
