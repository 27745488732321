import React, { useState, useRef, useEffect } from "react";
import { Paper, Box, CardMedia, Grid, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";
import SlideTemplate from "../../components/SlideTemplate";
const eq_1 = "$Q=\\frac{\\Delta V}{\\Delta t}$";
const eq_2 =
  "$W_s=d\\left(\\frac{v^2}{2}\\right)+g \\cdot d z+\\int vol \\cdot dp+F$";
const eq_3 =
  "$\\int \\mathrm{vol} \\cdot d p=\\int \\frac{d p}{\\rho}=\\frac{p_2-p_1}{\\rho}$";
const eq_10 = "$H=H_d+h_2-h_1$";
const eq_11 = "$ P_u=\\rho g Q H $";
const eq_12 = "$P_0=U \\cdot I$";
const eq_13 = "$\\eta=\\dfrac{P_u}{P_0}$";

function Slide1() {
  return (
    <SlideTemplate title="Teorie">
      <Typography>
        Așa cum se poate observa, lichidul intră în pompă prin racordul de
        aspirație și apoi, prin orificiul central de admisie, în rotorul
        constituit din două discuri profilate între care sunt dispuse paletele.
        Discul cu orificiul central se numește inel, iar cel prin care rotorul
        este fixat pe arborele prin care primește mișcarea de la motor se
        numește coroană. Atunci când rotorul se învârte, lichidul conținut în
        spațiile interpaletare este accelerat, sub acțiunea forțelor centrifuge
        și împins către periferie, fiind expulzat în camera colectoare. Rolul
        acestei camere nu este doar acela de a colecta lichidul și de a­l
        conduce către racordul de refulare ci și de a transforma o parte din
        energia cinetică de care lichidul dispune la ieșirea din rotor în
        energie potențială de presiune. În vederea realizării acestei
        transformări dintr­o formă de energie hidraulică în alta și pentru a
        putea colecta întreg debitul de lichid vehiculat, secțiunea transversală
        a acestei camere crește continuu până la ieșirea din pompă prin racordul
        de refulare.
      </Typography>
    </SlideTemplate>
  );
}

function Slide2() {
  return (
    <SlideTemplate title="Teorie">
      <Typography>Înălțimea de pompare:</Typography>
      <Latex displayMode={true}>{eq_10}</Latex>
      <Typography>
        Puterea utilă P<sub>u</sub> reprezintă puterea transferată lichidului la
        trecerea prin pompă:
      </Typography>
      <Latex displayMode={true}>{eq_11}</Latex>
      <Typography>
        Puterea absorbită P<sub>0</sub> reprezintă puterea aplicată la axul
        motor pentru a realiza pomparea lichidului:
      </Typography>
      <Latex displayMode={true}>{eq_12}</Latex>
      <Typography>Randamentul η:</Typography>
      <Latex displayMode={true}>{eq_13}</Latex>
    </SlideTemplate>
  );
}
function Slide3() {
  return (
    <SlideTemplate title="Teorie">
      <Typography>
        În vederea caracterizării funcționării pompelor centrifuge este necesară
        introducerea unor mărimi care să cuantifice cantitatea de lichid care
        trece prin pompă, schimbul energetic care are loc în pompă precum și
        eficiența acestuia. În cazul tuturor mașinilor hidraulice care intră în
        categoria generatoare aceste mărimi, numite și parametri funcționali,
        sunt: debitul, înălțimea de pompare, puterea absorbită, puterea utilă,
        randamentul și turația.
      </Typography>
    </SlideTemplate>
  );
}
function Slide4() {
  return (
    <SlideTemplate title="Debitul Q">
      <Typography>
        Debitul reprezintă cantitatea de lichid care trece prin secțiunea de
        ieșire (racordul de refulare) în unitatea de timp. În cazul pompelor
        centrifuge, în ipoteza incompresibilității lichidelor vehiculate, se
        utilizează debitul volumic, exprimat în unități SI în [m<sup>3</sup> /
        s]:
      </Typography>

      <Latex displayMode={true}>{eq_1}</Latex>
      <Typography>
        ΔV reprezintă volumul de lichid care trece prin racordul de refulare în
        intervalul de timp Δt
      </Typography>
    </SlideTemplate>
  );
}
function Slide5() {
  return (
    <SlideTemplate title="Înălțimea de pompare H">
      <Typography>
        Reprezintă energia specifică totală primită de lichid la trecerea prin
        pompă și, prin urmare, poate fi determinată că diferența între energia
        specifică totală a lichidului de la intrarea și ieșirea în pompă.
        Energia specifică totală poate fi exprimată atât ca energia unității de
        greutate de lichid, notată cu H și exprimată în SI în unități de lungime
        [m] sau ca energia ce revine unității de masă, exprimată în SI în [J/K
        g].
      </Typography>

      <Latex displayMode={true}>{eq_1}</Latex>
      <Typography>
        ΔV reprezintă volumul de lichid care trece prin racordul de refulare în
        intervalul de timp Δt
      </Typography>
    </SlideTemplate>
  );
}
function Slide6(props) {
  return (
    <SlideTemplate title="Înălțimea de pompare H">
      <Typography>
        Pentru stabilirea expresiei înălțimii de pompare se pornește de la
        principiul I al termodinamicii, aplicat unității de masă de lichid care
        trece printr­un volum de control aproximat a coincide cu interiorul
        pompei:
      </Typography>

      <Latex displayMode={true}>{eq_2}</Latex>
      <Typography>
        - W<sub>s</sub> reprezintă lucrul mecanic aplicat la arborele motor
      </Typography>

      <Typography>
        -<Latex>{"$\\; d(\\frac{v^2}{2}) \\;$"}</Latex>
        reprezintă variația energiei cinetice;
      </Typography>
      <Typography>
        -<Latex>{"$\\; g \\cdot dz \\;$"}</Latex>
        reprezintă variația energiei potențiale de poziție;
      </Typography>
      <Typography>
        -<Latex>{"$ \\; \\int vol \\cdot dp \\; $"}</Latex>
        reprezintă variația energiei potențiale de presiune (în condițiile în
        care vol = volumul specific sau volumul unității de masă). Dacă se
        notează cu p<sub>1</sub> și p<sub>2</sub> presiunile la intrare
        (racordul de aspirație) respectiv la ieșire (racordul de refulare) din
        pompă și se admite ipoteza incompresibilității lichidelor, se poate
        scrie:
      </Typography>

      <Latex displayMode="true">{eq_3}</Latex>

      <Typography>
        - F reprezintă energia disipată prin frecare și transformata ireversibil
        în căldură
      </Typography>
    </SlideTemplate>
  );
}
function Slide7() {
  return (
    <Box>
      <Typography
        sx={{}}
        component="h1"
        variant="h6"
        align="center"
        color="text.primary"
        gutterBottom
      >
        Înălțimea de pompare H
      </Typography>

      <Box
        sx={{
          mt: 3,
          px: 10,
        }}
      >
        <Typography
          variant="h6"
          align="right"
          color="text.secondary"
          component="p"
        >
          Mecanica Fluidelor - lucrări practice - UTCluj
        </Typography>
      </Box>
    </Box>
  );
}
const Slides = () => {
  return (
    <Carousel
      navButtonsAlwaysVisible={true}
      autoPlay={false}
      swipe={false}
      height={"500px"}
    >
      <Slide1 />
      <Slide3 />
      <Slide4 />
      <Slide5 />
      <Slide6 />
      <Slide2 />
      {/* <Slide7 /> */}
    </Carousel>
  );
};
const Teorie = () => {
  return (
    <div>
      <Paper elevation={3} sx={{ p: 2, mt: 4 }}>
        <Slides />
      </Paper>
    </div>
  );
};

export default Teorie;
//
