import React, { useState, useEffect } from "react";
import Equation from "./Equation";
import Animation from "./Animation";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ContentsList from "./ContentsList";
import Carousel from "react-material-ui-carousel";
import { Paper, Button, Grow } from "@mui/material";
function Slide1() {
  return (
    <div>
      <Typography
        sx={{}}
        component="h1"
        variant="h6"
        align="center"
        color="text.primary"
        gutterBottom
      >
        Cum functioneaza?
      </Typography>
      <Box
        sx={{
          mt: 3,
          px: 10,
        }}
      >
        <Typography>
          O pompă peristaltică este un tip specializat de pompă care este
          utilizată pentru pomparea a numeroase tipuri diferite de fluide.
          Fluidul se găsește în interiorul unui tub flexibil, fiind mutat de
          mișcarea circulară a pompei.
        </Typography>
      </Box>
    </div>
  );
}
function Slide2() {
  return (
    <div>
      <Typography
        sx={{}}
        component="h1"
        variant="h6"
        align="center"
        color="text.primary"
        gutterBottom
      >
        Cum functioneaza?
      </Typography>
      <Box
        sx={{
          mt: 3,
          px: 10,
        }}
      >
        <Typography>
          În cazul pompei peristaltice rotative, un rotor, având un anumit număr
          de aripioare sau palete plasate exterior, comprimă periodic tubul
          flexibil. Pe măsură ce rotorul se mișcă, partea tubului aflată sub
          compresie se închide forțând lichidul să se deplaseze prin tub.
        </Typography>
      </Box>
    </div>
  );
}
function Slide3() {
  return (
    <div>
      <Typography
        sx={{}}
        component="h1"
        variant="h6"
        align="center"
        color="text.primary"
        gutterBottom
      >
        Cum functioneaza?
      </Typography>
      <Box
        sx={{
          mt: 3,
          px: 10,
        }}
      >
        <Typography>
          Ulterior, după ce tubul revine în starea sa normală, după trecerea
          unui cantități de lichid comprimat, o altă cantitate de fluid este
          introdus în pompă. Acest proces este denumit peristalsis, fiind
          utilizat în multe sisteme biologice, așa cum ar fi tubul digestiv.
        </Typography>
      </Box>
    </div>
  );
}
const Slides = (props) => {
  return (
    <Carousel
      indicators={true}
      navButtonsAlwaysVisible={true}
      autoPlay={false}
      swipe={false}
      sx={{
        height: "100%",
      }}
    >
      <Slide1 />
      <Slide2 />
      <Slide3 />
    </Carousel>
  );
};
const PompaPeristaltica = () => {
  return (
    <div>
      <Container
        disableGutters
        maxWidth="md"
        component="main"
        sx={{ pt: 4, pb: 4 }}
      >
        <Typography
          component="h1"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Pompa Peristaltica
        </Typography>
      </Container>
      <Container>
        <Paper
          elevation={3}
          sx={{
            p: 5,
            // height: "60vh",
          }}
        >
          <Grid container spacing={8} justifyContent="space-evenly">
            <Grid item xs={8} sm={8} md={8} lg={6} xl={6}>
              <Animation animation="peristaltic_pump" />
            </Grid>
            <Grid
              item
              xs={8}
              sm={8}
              md={8}
              lg={6}
              xl={6}
              display="flex"
              // alignItems="center"
              // justifyContent="center"
            >
              <Paper
                sx={{
                  flexGrow: 1,
                  pb: 3,
                  pt: 5,
                }}
              >
                <Slides />
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default PompaPeristaltica;
