import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Button,
  Grow,
  CardMedia,
  ListItemText,
  List,
  ListItem,
  Typography,
  Container,
} from "@mui/material";
import rotorsImage from "../../assets/centrifugal_pump/rotors.png";
import curbaPompeImage from "../../assets/centrifugal_pump/curba_pompe.png";
import Carousel from "react-material-ui-carousel";

import SlideTemplate from "../../components/SlideTemplate";
function Slide1() {
  return (
    <SlideTemplate title="Aplicabilitate">
      <Typography>
        Utilizată în foarte multe domenii, de exemplu în instalații pentru
        construcții (apă rece, apă caldă menajeră, sisteme de încălzire),
        alimentări cu apă etc. Debite mici și sarcini mari. Exemplu de
        parametri: debitul de 300 m<sup>3</sup>/h = 0,083 m<sup>3</sup>/s,
        sarcina de 50 m, puterea pompei 52 kW
      </Typography>
    </SlideTemplate>
  );
}
function Slide2() {
  return (
    <SlideTemplate title="Tipuri de rotoare utilizabile la pompe centrifuge">
      <CardMedia
        component="img"
        width="auto"
        image={rotorsImage}
        alt={`Tipuri de rotoare utilizabile la pompe centrifuge`}
      />
    </SlideTemplate>
  );
}
function Slide3() {
  return (
    <SlideTemplate title="Curba caracteristică a unei pompe centrifugale">
      <CardMedia
        component="img"
        width="auto"
        image={curbaPompeImage}
        alt={`Caracteristică universală a pompei centrifuge`}
      />
    </SlideTemplate>
  );
}
const Slides = (props) => {
  return (
    <Carousel
      navButtonsAlwaysVisible={true}
      autoPlay={false}
      swipe={false}
      height={"400px"}
    >
      <Slide1 />
      <Slide2 />
      <Slide3 />
    </Carousel>
  );
};
export default Slides;
