import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import DrawerContext from "../DrawerContext";
import ContentsList from "./ContentsList";
import { useContext } from "react";
export default function MenuDrawer() {
  const { drawerState, toggleDrawer } = useContext(DrawerContext);
  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer("left", false)}
      onKeyDown={toggleDrawer("left", false)}
    >
      <ContentsList />
    </Box>
  );

  return (
    <div>
      <Drawer
        anchor="left"
        open={drawerState}
        onClose={toggleDrawer("left", false)}
      >
        {/* lista cu elementele din drawer */}
        {list()}
      </Drawer>
    </div>
  );
}
