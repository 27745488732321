import {
  Slider,
  Paper,
  Box,
  CardMedia,
  Grid,
  Switch,
  FormControlLabel,
  FormGroup,
  Typography,
  Button,
  IconButton,
} from "@mui/material";

import React, { useState } from "react";
import pumpImage from "../../assets/centrifugal_pump/centrifugal_pump2.svg";
import PumpSVG from "./PumpSVG";
import TabelDate from "./TabelDate";
import { Grafic } from "./Grafic";
import Instrumente from "./Instrumente";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { useFullscreen } from "../../utils/useFullscreen";
import {
  openFullscreen,
  closeFullscreen,
  IsFullScreen,
} from "../../utils/fullScreen.js";
const p = 1000;
const g = 10;
const hd_iesire = 0.017;
const hd_intrare = 0.02;
const hd = (hd_iesire - hd_intrare).toFixed(3);

const LabPompeCentrifugale = () => {
  const { fullscreenRef, enterFullscreen, exitFullscreen, fullscreenActive } =
    useFullscreen();

  const [running, setRunning] = useState(false);
  const [vana, setVana] = useState(50);
  const [putereConsumata, setPutereConsumata] = useState(0);
  const [sarcinaLaIesire, setSarcinaLaIesire] = useState(0);
  const [sarcinaLaIntrare, setSarcinaLaIntrare] = useState(0);
  const [debit, setDebit] = useState(0);
  const [graphData, setGraphData] = useState({
    randament: [],
    inaltimeDePompare: [],
    putereConsumata: [],
    sarcinaLaIesire: [],
    sarcinaLaIntrare: [],
    putereUtila: [],
  });
  // valori "citite"
  const calculDebit = (vana) => {
    return vana;
  };
  const calculSarcinaLaIesire = (vana) => {
    return (24 - 0.22 * vana).toFixed(1);
  };
  const calculSarcinaLaIntrare = (vana) => {
    return (-0.01 * vana).toFixed(2);
  };
  const calculPutereConsumata = (vana) => {
    return (207 + vana / 1.8).toFixed(0);
  };
  // valori "calculate"
  const calculInaltimeDePompare = () => {
    return (
      parseInt(hd) + parseInt(sarcinaLaIesire) - parseInt(sarcinaLaIntrare)
    );
  };
  const calculPutereUtila = () => {
    return (
      ((p * g * parseInt(debit)) / 60000) *
      calculInaltimeDePompare()
    ).toFixed(0);
  };
  const calculRandament = () => {
    if (putereConsumata == 0) return 0;
    return ((100 * calculPutereUtila()) / putereConsumata).toFixed(0);
  };
  const InregistreazaCitire = (debit) => {
    setGraphData(() => {
      let newGraphData = graphData;
      newGraphData.randament = [
        ...newGraphData.randament,
        { x: debit, y: calculRandament() },
      ];
      newGraphData.inaltimeDePompare = [
        ...newGraphData.inaltimeDePompare,
        { x: debit, y: calculInaltimeDePompare() },
      ];
      newGraphData.putereConsumata = [
        ...newGraphData.putereConsumata,
        { x: debit, y: putereConsumata },
      ];
      newGraphData.sarcinaLaIntrare = [
        ...newGraphData.sarcinaLaIntrare,
        { x: debit, y: sarcinaLaIntrare },
      ];
      newGraphData.sarcinaLaIesire = [
        ...newGraphData.sarcinaLaIesire,
        { x: debit, y: sarcinaLaIesire },
      ];
      newGraphData.putereUtila = [
        ...newGraphData.putereUtila,
        { x: debit, y: calculPutereUtila() },
      ];

      // daca nu pun ... inainte de "newGraphData" react nu
      // considera ca s-a schimbat starea si nu face rerender
      return { ...newGraphData };
    });
  };
  return (
    <Box
      className="lab-virt-pompe-centrifugale"
      id="lab-virt-pompe-centrifugale"
      sx={{ width: "100%", mt: 5, backgroundColor: "#ffffff" }}
    >
      <main ref={fullscreenRef}>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            align="center"
            color="text.primary"
            gutterBottom
            sx={{
              mt: 3,
              flexGrow: 1,
            }}
          >
            Laborator Virtual
          </Typography>
          {fullscreenActive ? (
            <IconButton onClick={exitFullscreen} aria-label="exit-fullscreen">
              <FullscreenExitIcon />
            </IconButton>
          ) : (
            <IconButton onClick={enterFullscreen} aria-label="fullscreen">
              <FullscreenIcon />
            </IconButton>
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <Paper
              elevation={3}
              sx={{
                p: 2,
              }}
            >
              <Typography
                component="h1"
                variant="h6"
                align="center"
                color="text.primary"
                gutterBottom
              >
                Panou Comanda
              </Typography>
              <FormGroup
                sx={{
                  alignItems: "center",
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(val) => {
                        setRunning(val.target.checked);
                        if (val.target.checked) {
                          setDebit(calculDebit(vana));
                          // console log debit
                          setPutereConsumata(calculPutereConsumata(vana));
                          setSarcinaLaIesire(calculSarcinaLaIesire(vana));
                          setSarcinaLaIntrare(calculSarcinaLaIntrare(vana));
                        } else {
                          setDebit(0);
                          setPutereConsumata(0);
                          setSarcinaLaIesire(0);
                          setSarcinaLaIntrare(0);
                        }
                      }}
                    />
                  }
                  label="Alimentare Motor"
                />
              </FormGroup>
              <Typography
                variant="subtitle1"
                align="center"
                color="text.primary"
                // gutterBottom
              >
                Pozitie Vana
              </Typography>
              <Slider
                defaultValue={50}
                aria-label="Default"
                valueLabelDisplay="auto"
                onChange={(val) => {
                  setVana(val.target.value);
                  // console.log(`value from onChange ${vana}`);
                  if (running) {
                    setDebit(calculDebit(val.target.value));
                    // console.log("debit: " + debit);
                    setPutereConsumata(calculPutereConsumata(val.target.value));
                    setSarcinaLaIesire(calculSarcinaLaIesire(val.target.value));
                    setSarcinaLaIntrare(
                      calculSarcinaLaIntrare(val.target.value)
                    );
                  }
                }}
              />
              <Button
                // vrem sa nu poti apasa pe buton daca running == 0
                disabled={!running}
                onClick={() => {
                  if (running) InregistreazaCitire(debit);
                }}
                variant="contained"
              >
                Înregistrează Citire
              </Button>
            </Paper>
          </Grid>

          <Grid item md={6}>
            <Paper
              elevation={3}
              sx={{
                p: 2,
              }}
            >
              <PumpSVG
                running={running}
                debit={debit}
                sarcinaLaIesire={sarcinaLaIesire}
                vana={vana}
                // sarcinaLaIntrare={sarcinaLaIntrare}
              />
            </Paper>
          </Grid>

          <Grid item md={3}>
            <Instrumente
              putereConsumata={putereConsumata}
              debit={debit}
              sarcinaLaIesire={sarcinaLaIesire}
              sarcinaLaIntrare={sarcinaLaIntrare}
            />
          </Grid>
        </Grid>

        <Grafic graphData={graphData} />
        {!fullscreenActive && <TabelDate graphData={graphData} />}
      </main>
    </Box>
  );
};

export default LabPompeCentrifugale;
