import React, { useState, useEffect } from "react";
import Equation from "./Equation";
import Animation from "./Animation";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ContentsList from "./ContentsList";
import Carousel from "react-material-ui-carousel";
import { Paper, Button, Grow } from "@mui/material";
function Slide1() {
  return (
    <div>
      <Typography
        sx={{}}
        component="h1"
        variant="h6"
        align="center"
        color="text.primary"
        gutterBottom
      >
        Utilizare
      </Typography>
      <Box
        sx={{
          mt: 3,
          px: 10,
        }}
      >
        <Typography>
          Cele mai utilizate în acționări hidrostatice, la Grupul de Ulei sub
          Presiune (GUP) din sistemul de reglare automată a vitezei turbinelor
          hidraulice.
        </Typography>
      </Box>
    </div>
  );
}
function Slide2() {
  return (
    <div>
      <Typography
        sx={{}}
        component="h1"
        variant="h6"
        align="center"
        color="text.primary"
        gutterBottom
      >
        Randament
      </Typography>
      <Box
        sx={{
          mt: 3,
          px: 10,
        }}
      >
        <Typography>
          Au randamente ridicate, debite foarte mici și sarcini mari. Exemplu:
          debit 0,5 litri/s = 0,0005 m 3 /s, creșterea presiunii de 200 bar,
          putere de 11 kW
        </Typography>
      </Box>
    </div>
  );
}
const Slides = (props) => {
  return (
    <Carousel
      indicators={true}
      navButtonsAlwaysVisible={true}
      autoPlay={false}
      swipe={false}
      sx={{
        height: "100%",
      }}
    >
      <Slide1 />
      <Slide2 />
    </Carousel>
  );
};
const PompaCuPistonase = () => {
  return (
    <div>
      <Container
        disableGutters
        maxWidth="md"
        component="main"
        sx={{ pt: 4, pb: 4 }}
      >
        <Typography
          component="h1"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Pompa cu Pistonașe Axiale
        </Typography>
      </Container>
      <Container>
        <Paper
          elevation={3}
          sx={{
            p: 5,
            // height: "60vh",
          }}
        >
          <Grid container spacing={8} justifyContent="space-evenly">
            <Grid
              item
              xs={8}
              sm={8}
              md={8}
              lg={6}
              xl={6}
              // display='flex'
              // direction='column'
            >
              <iframe
                title="Variable Volume Fuel Pump"
                frameborder="0"
                allowfullscreen
                mozallowfullscreen="true"
                webkitallowfullscreen="true"
                allow="autoplay; fullscreen; xr-spatial-tracking"
                xr-spatial-tracking
                execution-while-out-of-viewport
                execution-while-not-rendered
                web-share
                src="https://sketchfab.com/models/579e52ea9999430f9ff3fa3b380a7f7d/embed?autostart=1&annotations_visible=1&transparent=1&ui_infos=0&ui_stop=0&ui_watermark_link=0&ui_watermark=0&ui_hint=0&dnt=1"
                height="100%"
                width="100%"
              ></iframe>{" "}
              {/* <Typography
                    variant="h6"
                    align="center"
                    color="text.secondary"
                    component="p"
                  >
                    cu disc fulant
                  </Typography> */}
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={6} xl={6}>
              <Animation animation="pompa_cu_pistonase_axiale" />
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={6} xl={6}>
              <Typography
                variant="h6"
                align="center"
                color="text.secondary"
                component="p"
              >
                cu disc fulant
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={6} xl={6}>
              <Typography
                variant="h6"
                align="center"
                color="text.secondary"
                component="p"
              >
                cu ax înclinat
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Paper
                sx={{
                  flexGrow: 1,
                  pb: 3,
                  pt: 5,
                }}
              >
                <Slides />
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default PompaCuPistonase;
