import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import Slider from "@mui/material/Slider";
import Container from "@mui/material/Container";
import anime from "animejs/lib/anime.es.js";
const Animation = (props) => {
  // if there is a delay specified, let the delay variable
  // equal the delay specified in props; if not, let the
  // delay variable be 1second
  let delay = props.delay ? parseInt(props.delay) : 1000;
  const images = {
    gear_pump: {
      text: "Pompă cu roţi dinţate",
      encoding:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAGQAQMAAABI+4zbAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAADRJREFUeNrtwQENAAAAwqD3T20PBxQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAI8GdsAAAU8KxhAAAAAASUVORK5CYII=",
      no_of_frames: 10,
    },
    surub_arhimede: {
      text: "Șurub Arhimede",
      encoding:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfkAAAFtAQMAAAA+j4HyAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAAC1JREFUeNrtwQEBAAAAgiD/r25IQAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlwZcrQABNO9lPQAAAABJRU5ErkJggg==",
      no_of_frames: 50,
    },
    peristaltic_pump: {
      text: "Pompă Peristaltică",
      encoding:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAoAAAAHCAQMAAABFe+qaAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAADpJREFUeNrtwTEBAAAAwqD1T20JT6AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPgYjmIAAfIHL5wAAAAASUVORK5CYII=",
      no_of_frames: 27,
    },
    pompa_cu_angrenaje_interioare: {
      text: "Pompă cu Angrenaje Interioare",
      encoding:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAoAAAAHgAQMAAAAPH06nAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAAD1JREFUeNrtwQENAAAAwqD3T20ON6AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA4M8Al+AAAUvMG0oAAAAASUVORK5CYII=",
      no_of_frames: 18,
    },
    pompa_cu_pistonase_axiale: {
      text: "Pompă cu Pistonașe Axiale",
      encoding:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgYAAAFFAQMAAACt1be8AAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAACxJREFUeNrtwYEAAAAAw6D5Ux/hAlUBAAAAAAAAAAAAAAAAAAAAAAAAAADfAFPKAAEwuAWxAAAAAElFTkSuQmCC",
      no_of_frames: 36,
    },
  };
  const text = {
    gear_pump: "Pompă cu roţi dinţate",
    surub_arhimede: "Șurub Arhimede",
    gear_teeth: "Cum interacționeaza dinții roțiilor",
    peristaltic_pump: "Pompă Peristaltică",
    // pompa_cu_pistonase_axiale: "cu ax înclinat",
    pompa_cu_angrenaje_interioare: "Pompă cu Angrenaje Interioare",
  };

  // console.log(images[props.animation]["text"]);
  // nr de frameuri din animatie
  // var no_of_frames = Object.keys(images[props.animation]).length;

  const [animationFrame, setAnimationFrame] = useState(0);
  const [frame, setFrame] = useState(0);
  // run this function when page first loads
  // this function makes the slider go back and forth
  // to show the user how to use the animation
  useEffect(() => {
    // animateSlider();
    var battery = {
      charged: 20,
    };
    // animateSlider takes the battery.charged variable and
    // changes it to the target variable
    const animateSlider = () => {
      anime({
        targets: battery,
        keyframes: [{ charged: 70 }, { charged: 30 }, { charged: 50 }],
        round: 1,
        duration: 5500,
        easing: "cubicBezier(0.000, 0.000, 0.580, 1.000)",

        update: function () {
          setAnimationFrame(battery.charged);
          setFrame(battery.charged % images[props.animation]["no_of_frames"]);
        },
      });
    };
    setTimeout(() => {
      animateSlider();
    }, delay);
  }, []);

  return (
    <div>
      <Container
      // maxWidth="md"
      >
        <Box
          sx={{
            fontStyle: "italic",
          }}
        >
          <img
            className={`${props.animation}${frame}`}
            alt={images[props.animation]["text"]}
            src={images[props.animation]["encoding"]}
          />

          <Typography
            variant="h6"
            align="center"
            color="text.secondary"
            component="p"
          >
            {text[props.animation]}
          </Typography>
          <Slider
            value={animationFrame}
            defaultValue={50}
            onChange={(val) => {
              // console.log(images[`${val.target.value % no_of_frames}.gif`]);

              setFrame(
                val.target.value % images[props.animation]["no_of_frames"]
              );

              setAnimationFrame(val.target.value);
            }}
          />
        </Box>
      </Container>
    </div>
  );
};

export default Animation;
