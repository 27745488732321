import React from "react";
import { Paper, Box, CardMedia, Grid, Typography } from "@mui/material";
// import GaugeChart from "react-gauge-chart";
import Gauge from "./Gauge";

const Instrumente = (props) => {
  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
      }}
    >
      <Typography
        component="h1"
        variant="h6"
        align="center"
        color="text.primary"
        gutterBottom
      >
        Instrumente
      </Typography>
      <Typography
        variant="subtitle1"
        align="center"
        color="text.primary"
        gutterBottom
      >
        Putere Consumata: {props.putereConsumata} W
      </Typography>
      <Typography
        variant="subtitle1"
        align="center"
        color="text.primary"
        gutterBottom
      >
        {/* Debit: */}
        Debit: {props.debit} L / Min
      </Typography>
      {/* <Gauge></Gauge> */}
      {/* <GaugeChart
        id="gauge-chart-debit"
        nrOfLevels={20}
        percent={props.debit / 100}
        animate={false}
        textColor={"#000000"}
        formatTextValue={(value) => `${value} L / Min`}
      /> */}
      <Typography
        variant="subtitle1"
        align="center"
        color="text.primary"
        gutterBottom
      >
        Sarcina la iesire: {props.sarcinaLaIesire} m
      </Typography>

      {/* <GaugeChart
        id="gauge-chart-sarcina-la-iesire"
        nrOfLevels={20}
        percent={1 - props.debit / 100}
        animate={false}
        textColor={"#000000"}
        formatTextValue={(value) => `${props.sarcinaLaIesire} m`}
      /> */}
      <Typography
        variant="subtitle1"
        align="center"
        color="text.primary"
        gutterBottom
      >
        {/* Sarcina la intrare */}
        Sarcina la intrare: {props.sarcinaLaIntrare} m
      </Typography>
      {/* <GaugeChart
        id="gauge-chart-sarcina-la-intrare"
        nrOfLevels={20}
        percent={1 - props.debit / 100}
        animate={false}
        textColor={"#000000"}
        formatTextValue={(value) => `${props.sarcinaLaIntrare} m`}
      /> */}
    </Paper>
  );
};
export default Instrumente;
