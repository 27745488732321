import React, { useState, useRef, useEffect } from "react";
import { Paper, Box, CardMedia, Grid, Typography } from "@mui/material";
const SlideTemplate = (props) => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        component="h1"
        variant="h6"
        align="center"
        color="text.primary"
        gutterBottom
      >
        {props.title}
      </Typography>
      <Box
        sx={{
          mt: 3,
          px: 10,
        }}
      >
        {props.children}
        <Typography
          variant="h6"
          align="right"
          color="text.secondary"
          component="p"
        >
          Mecanica Fluidelor - lucrări practice - UTCluj
        </Typography>
      </Box>
    </Box>
  );
};
export default SlideTemplate;
