import React from "react";
import Animation from "./Animation";
import { Grid, Button } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import gifSurubArhimedeApa from "../assets/surub_arhimede/gifSurubArhimedeApa.gif";
import gifTruckMixer from "../assets/surub_arhimede/operation_of_a_truck_mixer.gif";
import Carousel from "react-material-ui-carousel";
import { CardMedia } from "@mui/material/";
function Slide1() {
  return (
    <div>
      {/* <Animation animation="gear_teeth"></Animation> */}
      <CardMedia
        component="img"
        width="auto"
        image={gifSurubArhimedeApa}
        alt={`Animatie Surub Arhimede`}
      />
    </div>
  );
}
function Slide2() {
  return (
    <div>
      {/* <Animation animation="gear_teeth"></Animation> */}
      <CardMedia
        component="img"
        width="auto"
        image={gifTruckMixer}
        alt={`Operation of a Truck Mixer`}
      />
    </div>
  );
}
const Slides = (props) => {
  return (
    <Carousel navButtonsAlwaysVisible={true} autoPlay={false}>
      <Slide1 />
      <Slide2 />
    </Carousel>
  );
};
const SurubulLuiArhimede = () => {
  return (
    <div>
      <Container
        disableGutters
        maxWidth="md"
        component="main"
        sx={{ pt: 4, pb: 4 }}
      >
        <Typography
          component="h1"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Șurubul lui Arhimede
        </Typography>
      </Container>
      <Container>
        <Paper
          elevation={3}
          sx={{
            p: 5,
          }}
        >
          <Grid container justifyContent="space-evenly">
            <Grid item xs={8} sm={8} md={8} lg={6} xl={6}>
              <Animation animation="surub_arhimede" />
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={6} xl={6}>
              <Paper>
                <Slides />
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default SurubulLuiArhimede;
