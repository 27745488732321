import React from "react";

import { Box, SvgIcon } from "@mui/material";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { Link } from "react-router-dom";
export const chapters = [
  "Pompe Centrifugale",
  "Pompe cu Roți Dințate",
  /* "Motoare Hidraulice", */
  "Șurubul lui Arhimede",
  "Pompa cu diafragmă",
  "Pompa peristaltică",
  "Pompa cu pistonașe Axiale",
  // "Pompa cu pistonașe Radiale",
  "Pompa cu angrenaje interioare",
  // "Laborator Virtual",
];

const ContentsList = () => {
  return (
    <List>
      {chapters.map((text, index) => (
        <ListItem
          button
          component={Link}
          to={
            "/" +
            text
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .replaceAll(" ", "-")
          }
          key={text}
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <PlayCircleIcon />
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

// export default { ContentsList, chapters };
export default ContentsList;
