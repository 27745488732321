import React, { createContext, useState } from "react";
import MenuDrawer from "./components/MenuDrawer";
const DrawerContext = createContext();
export function DrawerProvider({ children }) {
  // ' state ' este variabila care spune daca este inchis sau
  // deschis drawer-ul
  const [drawerState, setDrawerState] = useState(false);

  // functia care face sa se schimbe variabila 'state'
  const toggleDrawer = (anchor, currentState) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerState(currentState);
  };
  return (
    <DrawerContext.Provider value={{ drawerState, toggleDrawer }}>
      {children}
    </DrawerContext.Provider>
  );
}

export default DrawerContext;
