import React, { useState, useEffect } from "react";
import Equation from "./Equation";
import Animation from "./Animation";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ContentsList from "./ContentsList";
import Carousel from "react-material-ui-carousel";
import { Fade, Paper, Button, Grow, CardMedia } from "@mui/material";
import ExplodedGearPump from "../assets/gear_pump/gear_pump_exploded.png";
function Slide1() {
  return (
    <div>
      <Typography
        sx={{}}
        component="h1"
        variant="h6"
        align="center"
        color="text.primary"
        gutterBottom
      >
        Cum functioneaza?
      </Typography>
      <Box
        sx={{
          mt: 3,
          px: 10,
        }}
      >
        <Typography>
          Uleiul care intră prin orificiul de aspiraţie sub acţiunea presiunii
          atmosferice umple spaţiile în creştere create prin ieşirea dinţilor
          din angrenare, fiind apoi transportat la periferia roţilor, spre
          orificiul de refulare. Linia de contact a dinţilor roţilor aflaţi în
          angrenare permite izolarea celor două zone cu presiuni diferite.
        </Typography>
      </Box>
    </div>
  );
}
function Slide2() {
  return (
    <div>
      <CardMedia
        component="img"
        width="auto"
        image={ExplodedGearPump}
        alt={`Vedere Detaliată a pompei cu roți dințate`}
      />
    </div>
  );
}
const Slides = (props) => {
  return (
    <Carousel
      indicators={true}
      navButtonsAlwaysVisible={true}
      autoPlay={false}
      swipe={false}
      sx={{
        height: "100%",
      }}
    >
      <Slide1 />
      <Slide2 />
    </Carousel>
  );
};
const PompeCuRotiDintate = () => {
  return (
    <div>
      <Container
        disableGutters
        maxWidth="md"
        component="main"
        sx={{ pt: 4, pb: 4 }}
      >
        <Typography
          component="h1"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Pompe cu Roți Dințate
        </Typography>
      </Container>
      <Container>
        <Paper
          elevation={3}
          sx={{
            p: "2%",
            // height: "60vh",
          }}
        >
          <Grid container spacing={8} justifyContent="space-evenly">
            <Grid item xs={8} sm={8} md={8} lg={6} xl={6}>
              <Fade>
                <Animation animation="gear_pump" />
              </Fade>
            </Grid>
            <Grid
              item
              xs={8}
              sm={8}
              md={8}
              lg={6}
              xl={6}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Paper
                sx={{
                  flexGrow: 1,
                  pb: 3,
                  pt: 5,
                }}
              >
                <Slides />
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default PompeCuRotiDintate;
